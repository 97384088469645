import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import { AnimateSharedLayout } from 'framer-motion';

import { Input } from 'components/Atoms/Input/Input';
import { Button } from 'components/Atoms/Button/Button';
import { CTA } from 'components/Atoms/CTA/CTA';
import { validationSchema, handleSendMessage } from 'helpers/schema';
import { useTranslations } from 'hooks/useTranslations';
import { Wrapper, Form } from './ContactForm.style';
import type { StatusForm } from 'types';
import { parentVarians, childVariants, wrapperVariants } from './variants';

const initialValues = { email: '', title: '', message: '' };
const DEFAULT_STATE_TIME = 4000;

export const ContactForm: FC = ({}) => {
  const { contactForm } = useTranslations();
  const [statusForm, setStatusForm] = useState<StatusForm>('idle');
  const { email, title, message, send, requiredMessage, emailMessage } = contactForm;

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues,
    validationSchema: validationSchema({ requiredMessage, emailMessage }),
    onSubmit: async (data) => {
      setStatusForm('loading');
      try {
        await handleSendMessage(data);
        setStatusForm('success');
        setTimeout(() => {
          setStatusForm('idle');
        }, DEFAULT_STATE_TIME);
      } catch (error) {
        setStatusForm('error');
      }
    },
  });

  return (
    <AnimateSharedLayout>
      <Wrapper variants={wrapperVariants} layout>
        <Form onSubmit={handleSubmit} autoComplete="off" variants={parentVarians}>
          <Input
            name="email"
            label={email.label}
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            isError={!!(errors.email && touched.email)}
            errorMessage={errors.email}
          />

          <Input
            name="title"
            label={title.label}
            value={values.title}
            onBlur={handleBlur}
            onChange={handleChange}
            isError={!!(errors.title && touched.title)}
            errorMessage={errors.title}
          />

          <Input
            tag="textarea"
            name="message"
            label={message.label}
            value={values.message}
            onBlur={handleBlur}
            onChange={handleChange}
            isError={!!(errors.message && touched.message)}
            errorMessage={errors.message}
          />

          <CTA isButton type="submit">
            <Button
              variants={childVariants}
              disabled={statusForm !== 'idle'}
              label={send.label}
              status={statusForm}
            />
          </CTA>
        </Form>
      </Wrapper>
    </AnimateSharedLayout>
  );
};
