import * as Yup from 'yup';
import emailjs from 'emailjs-com';

interface FormInputs {
  email: string;
  title: string;
  message: string;
}

/**
 * @param  {FormInputs} data
 */
export const handleSendMessage = (data: FormInputs) => {
  return emailjs.send(
    process.env.GATSBY_EMAILJS_SERVICE_ID!,
    process.env.GATSBY_EMAILJS_TEMPLATE_ID!,
    data,
    process.env.GATSBY_EMAILJS_USER_ID!,
  );
};

interface SchemaErrors {
  emailMessage: string;
  requiredMessage: string;
}

/**
 * @param  {SchemaErrors} emailMessage
 * @param  {string} requiredMessage
 */
export const validationSchema = ({ emailMessage, requiredMessage }: SchemaErrors) =>
  Yup.object().shape({
    email: Yup.string().email(emailMessage).required(requiredMessage),
    title: Yup.string().required(requiredMessage),
    message: Yup.string().required(requiredMessage),
  });
