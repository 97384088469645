import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Wrapper = styled(motion.span)<{ isDisabled?: boolean }>`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  border: 1px solid ${({ theme }) => theme.colors.textLight};
  padding: 1.2rem 4vw;
  min-width: 20rem;
  max-width: 30rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 4.4rem;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;
