import styled from 'styled-components';

import { calcHeight } from 'assets/styles/mixins';

export const Wrapper = styled.section`
  position: relative;
  height: 80vh;
  ${calcHeight(80)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.level1};
`;

export const Inner = styled.div`
  margin: auto 0 3rem;
`;
