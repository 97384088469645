import React from 'react';
import { AnimatePresence, Variants } from 'framer-motion';

import { Wrapper, Circle } from './Spinner.style';
import { containerVariants, childVariants } from './variants';

export const Spinner = ({ isLoading = false, ...props }) => (
  <AnimatePresence>
    {isLoading && (
      <Wrapper
        key="spinner"
        initial="hidden"
        animate="animate"
        exit="exit"
        variants={containerVariants}
        {...props}
      >
        <Circle variants={childVariants as Variants} />
        <Circle variants={childVariants as Variants} />
        <Circle variants={childVariants as Variants} />
      </Wrapper>
    )}
  </AnimatePresence>
);
