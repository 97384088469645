export const parentVarians = {
  visible: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.2,
    },
  },

  exit: {
    opacity: 0,
    y: -20,
  },
};

export const childVariants = {
  hidden: {
    y: 20,
    opacity: 0,
  },

  visible: {
    y: 0,
    opacity: 1,
  },

  exit: {
    y: -20,
    opacity: 0,
  },
};

export const wrapperVariants = {
  hidden: {
    y: 20,
    opacity: 0,
  },

  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
    },
  },

  exit: {
    y: -20,
    opacity: 0,
  },
};
