export const containerVariants = {
  hidden: {
    opacity: 0,
    y: -20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.3,
    },
  },
  exit: {
    opacity: 0,
    y: -20,
  },
};

export const childVariants = {
  animate: {
    y: [0, 10, -10],
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 1.2,
      ease: 'easeInOut',
    },
  },
};
