import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import type { InputTag } from 'types';

export const Wrapper = styled(motion.div)`
  width: 100%;
  margin-bottom: 1rem;
`;

export const StyledInput = styled.input<{ as: InputTag }>`
  width: 100%;
  padding: 1.6rem 2rem 1.4rem;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  color: ${({ theme }) => theme.colors.textLight};
  border-radius: 0.4rem;
  border: none;
  outline: none;
  position: relative;

  ${({ as }) =>
    as === 'textarea' &&
    css`
      min-height: 16rem;
      resize: none;
    `}

  ${({ theme }) => theme.mq.tablet} {
    padding: 1.5rem 1rem;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textLight};
    letter-spacing: 1px;
    font-size: ${({ theme }) => theme.font.size.body.l};
  }
`;

export const Error = styled(motion.div)`
  padding: 0.4rem 0.6rem;

  ${({ theme }) => theme.mq.tablet} {
    padding: 0.8rem 1rem;
  }
`;
