import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Contact } from 'components/Organisms/Contact/Contact';
import { ContentProvider } from 'providers/ContentProvider/ContentProvider';
import { SEO } from 'components/Organisms/SEO/SEO';

import type { ContactPageData } from 'types';
interface ContactTemplateProps {
  data: ContactPageData;
}

const ContactTemplate: FC<ContactTemplateProps> = ({ data }) => {
  const { seo, locale, metakeywords } = data.contactPageContent;

  return (
    <>
      <SEO seo={seo} lang={locale} keywords={metakeywords} />
      <ContentProvider data={data}>
        <Contact />
      </ContentProvider>
    </>
  );
};

export default ContactTemplate;

export const query = graphql`
  query CONTACT_PAGE_QUERY($locale: String!) {
    contactPageContent: datoCmsContactPage(locale: { eq: $locale }) {
      locale
      metakeywords
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ...ContactPage
    }
    socialIcons: allDatoCmsSocial(filter: { locale: { eq: $locale } }) {
      nodes {
        ...SocialIcon
      }
    }
    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }, sort: { fields: order }) {
      nodes {
        ...CategoryElement
      }
    }
  }
`;
