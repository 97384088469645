import React, { FC } from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';

import { Text } from 'components/Atoms/Text/Text';
import { Spinner } from 'components/Atoms/Spinner/Spinner';
import { useTranslations } from 'hooks/useTranslations';
import { Wrapper } from './Button.style';
import { buttonVariants } from './variants';
import type { StatusForm } from 'types';

interface ButtonProps {
  variants: Variants;
  label: string;
  status?: StatusForm;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  status = 'idle',
  disabled = false,
  label,
  ...rest
}) => {
  const {
    contactForm: { buttonStatus },
  } = useTranslations();

  return (
    <Wrapper {...rest} layout isDisabled={disabled}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          style={{ width: '100%' }}
          key={status}
          variants={buttonVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {status === 'idle' && <Text>{label}</Text>}
          {status === 'success' && <Text>{buttonStatus.success}</Text>}
          {status === 'error' && <Text>{buttonStatus.error}</Text>}
          {status === 'loading' && <Spinner isLoading />}
        </motion.div>
      </AnimatePresence>
    </Wrapper>
  );
};
