export const errorVariants = {
  hidden: {
    x: -10,
    opacity: 0,
  },

  visible: {
    x: 0,
    opacity: 1,
  },

  exit: {
    x: 10,
    opacity: 0,
  },
};

export const childVariants = {
  hidden: {
    y: 20,
    opacity: 0,
  },

  visible: {
    y: 0,
    opacity: 1,
  },

  exit: {
    y: -20,
    opacity: 0,
  },
};
