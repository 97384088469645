import React from 'react';

import { useContent } from 'providers/ContentProvider/ContentProvider';
import { HeroImage } from 'components/Molecules/HeroImage/HeroImage';
import { ContactForm } from 'components/Molecules/ContactForm/ContactForm';
import { SocialList } from 'components/Molecules/SocialList/SocialList';
import { SideNav } from 'components/Molecules/SideNav/SideNav';
import { useTranslations } from 'hooks/useTranslations';
import { getCurrentNavItem } from 'helpers';
import { Wrapper, Inner } from './Contact.style';

export const Contact: React.FC = () => {
  const { contactPageContent } = useContent();
  const { image } = contactPageContent;
  const { menu } = useTranslations();
  const [homeItem, contactItem] = getCurrentNavItem({ menu, elements: [0, 3] });

  return (
    <>
      <HeroImage image={image} />
      <Wrapper>
        <ContactForm />
        <Inner>
          <SocialList />
        </Inner>
      </Wrapper>
      <SideNav mainItem={contactItem} subItem={homeItem} />
    </>
  );
};
