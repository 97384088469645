import React, { FC, FocusEvent, ChangeEvent } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { Text } from 'components/Atoms/Text/Text';
import { Wrapper, StyledInput, Error } from './Input.style';
import type { InputTag } from 'types';
import { errorVariants, childVariants } from './variants';

interface InputProps {
  value?: string | number;
  isError?: boolean | undefined;
  errorMessage?: string | undefined;
  readonly name: string;
  readonly tag?: InputTag;
  readonly label: string;
  readonly onBlur: (e: FocusEvent<any>) => void;
  readonly onChange: (e: ChangeEvent<any>) => void;
}

export const Input: FC<InputProps> = ({
  value = '',
  isError = false,
  tag = 'input',
  errorMessage = '',
  name,
  label,
  onBlur,
  ...props
}) => {
  return (
    <Wrapper layout variants={childVariants}>
      <motion.div layout>
        <StyledInput as={tag} name={name} placeholder={label} id={name} {...props} />
      </motion.div>
      <AnimatePresence>
        {isError && (
          <Error variants={errorVariants} layout initial="hidden" animate="visible" exit="exit">
            <Text fontSize="1.4rem" color="textLight">
              {errorMessage}
            </Text>
          </Error>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};
