import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  max-width: 80rem;
  width: 70%;
  margin: 10px 0 30px 44px;
  padding: 5%;
  background-color: rgba(28, 27, 32, 0.6);
  border-radius: 0.8rem;

  ${({ theme }) => theme.mq.phone} {
    margin: auto 0 auto 44px;
  }

  ${({ theme }) => theme.mq.tablet} {
    padding: 3%;
  }
`;

export const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
